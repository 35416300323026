@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap);
*{
  box-sizing: border-box;
}

html{
  position: relative;
  overflow-x: hidden !important;
}

body {
  margin: 0;
  font-family: 'Quicksand', sans-serif;
  color: #324e63;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a, a:hover, a:visited {
  text-decoration: none;
  cursor: pointer;
  color: #FC0055;
}
.App {
  width: 100%;
  height: auto;
  min-height: 100vh;
  padding: 50px 20px;
  padding-top: 100px;
  display: flex;
  background: linear-gradient(-20deg, #0B3332 0%, #57ABA8 100%);
}
@media screen and (max-width: 768px) {
  .App {
    height: auto;
    min-height: 100vh;
    padding-top: 100px;
  }
}
.profileCardImg {
  width: 150px;
  height: 150px;
  margin-left: auto;
  margin-right: auto;
  transform: translateY(-50%);
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  z-index: 4;
  box-shadow: 0px 5px 50px 0px #87deaa, 0px 0px 0px 7px #87deaa;
}

.profileCardImg img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

@media screen and (max-width: 576px) {
  .profileCardImg {
    width: 120px;
    height: 120px;
  }
}
.profileCard {
  width: 100%;
  min-height: 460px;
  margin: auto;
  box-shadow: 0px 8px 60px -10px rgba(13, 28, 39, 0.6);
  background: #fff;
  border-radius: 12px;
  max-width: 700px;
  position: relative;
}

.profileCard .profileCardContent{
  margin-top: -35px;
  text-align: center;
  padding: 0 20px;
  padding-bottom: 40px;
  transition: all .3s;
}
