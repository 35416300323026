.App {
  width: 100%;
  height: auto;
  min-height: 100vh;
  padding: 50px 20px;
  padding-top: 100px;
  display: flex;
  background: linear-gradient(-20deg, #0B3332 0%, #57ABA8 100%);
}
@media screen and (max-width: 768px) {
  .App {
    height: auto;
    min-height: 100vh;
    padding-top: 100px;
  }
}