.profileCardImg {
  width: 150px;
  height: 150px;
  margin-left: auto;
  margin-right: auto;
  transform: translateY(-50%);
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  z-index: 4;
  box-shadow: 0px 5px 50px 0px #87deaa, 0px 0px 0px 7px #87deaa;
}

.profileCardImg img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

@media screen and (max-width: 576px) {
  .profileCardImg {
    width: 120px;
    height: 120px;
  }
}