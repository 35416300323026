@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');

*{
  box-sizing: border-box;
}

html{
  position: relative;
  overflow-x: hidden !important;
}

body {
  margin: 0;
  font-family: 'Quicksand', sans-serif;
  color: #324e63;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a, a:hover, a:visited {
  text-decoration: none;
  cursor: pointer;
  color: #FC0055;
}